import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEvents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/events', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/events/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, data) {
      const bodyFormData = new FormData()

      bodyFormData.append('name', data.name)
      bodyFormData.append('start_date', data.start_date)
      bodyFormData.append('end_date', data.end_date)
      bodyFormData.append('description', data.description)
      bodyFormData.append('mobile_number', data.mobile_number)
      bodyFormData.append('email', data.email)
      bodyFormData.append('image', data.image)


      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/events/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    editEvent(ctx, data) {
      const bodyFormData = new FormData()
      bodyFormData.append('id', data.id)
      bodyFormData.append('name', data.name)
      bodyFormData.append('mobile_number', data.mobile_number)
      bodyFormData.append('description', data.description)
      bodyFormData.append('start_date', data.start_date)
      bodyFormData.append('end_date', data.end_date)
      bodyFormData.append('email', data.email)
      data.image && bodyFormData.append('image', data.image)
  

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/events/update',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    deleteEvent(_ctx, _id) {
      const data = { id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/events/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
